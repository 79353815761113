<template>
<div class="container">
  <div class="margin-top-100"></div>
  <div class="row">
    <div class="col-md-12">
      <div class="login-page-title">
        登录
      </div>
    </div>
    <div class="col-xs-12 col-sm-2 col-md-3"></div>
    <div class="col-xs-12 col-sm-8 col-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <input type="text" class="form-control" v-model="username" placeholder="用户名" required>
          <small class="form-text text-muted"></small>
        </div>

        <div class="form-group">
          <el-input type="password" v-model="password" placeholder="密码" show-password required></el-input>
        </div>

        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" id="state7days"  v-model="remember_me">
          <label class="form-check-label">停留7天</label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <router-link to="/forget" class="right">找回密码</router-link>
        </div>
        
        <button type="submit" class="btn btn-primary submit-center">提交</button>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      remember_me: false,
    }
  },
  methods: {
    tigs(msg, type) {
      ElMessage({
        message: msg,
        type: type,
      })
    },
    async handleSubmit() {
      const response = await axios.post('/api/web/login', {
        username: this.username,
        password: this.password,
        remember_me: this.remember_me,
      });
      
      if(response.data.code == 200) {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('userId', response.data.data.userId);
        localStorage.setItem('username', response.data.data.username);
        localStorage.setItem('userDescription', response.data.data.description);
        // console.log(localStorage.getItem('token'));
        // this.$router.push('/');
        this.tigs(response.data.message, 'success')
        window.location.replace("/");
      } else {
        this.tigs(response.data.message, 'warning')
      }
      
    }
  }
}
</script>
